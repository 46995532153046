@import "tailwindcss";

body {
  font-family: "Inter Variable";
}

body {
    margin: 0;
    overflow: hidden;
}

/* Make everything non selectable by default to better simulate a native app */
* {
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
}

.text-shadow {
    filter: drop-shadow(0px 0px 5px black) drop-shadow(0px 0px 20px black);;
}

